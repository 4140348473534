import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { Button, Row, Col } from "react-bootstrap";
import BusyCalendar from "./BusyCalendar";
import * as moment from "moment";
import { IJob } from "@rsfApp/app/src/Models/Job";

const mapStateToProps = (state: RootState, ownProps: ISidebarAddApptProps) => {
  return {};
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ISidebarAddApptProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ISidebarAddApptProps {
  job: IJob;
  addAppointment: () => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ISidebarAddApptProps;

export interface ISidebarAddApptState {}

class SidebarAddAppt extends React.Component<Props, ISidebarAddApptState> {
  constructor(props: Props) {
    super(props);
  }

  public render() {
    const { addAppointment } = this.props;

    return (
      <Row>
        <Col sm={12}>
          {/* <Row>
            <Col sm={12}>
              <BusyCalendar dateSelected={this.dateSelected} response={null} />
            </Col>
          </Row> */}
          <Row>
            <Col sm={12} className="text-center">
              <Button onClick={addAppointment} className="btn btn-primary">
                Add Appointment
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  protected dateSelected = (date: moment.Moment) => {};
}

export default connector(SidebarAddAppt);
